import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useEvent } from './EventContext';
import './Style.scss';

const SignaturePad = () => {
  const { setSignatureSaved } = useEvent();
  const signaturePadRef = useRef(null);

  const handleSave = () => {
    if (signaturePadRef.current) {
      const dataURL = signaturePadRef.current.toDataURL();
      if (dataURL) {
        setSignatureSaved(dataURL);
        console.log('Signature data URL:', dataURL);
      } else {
        console.error('Failed to save signature.');
      }
    } else {
      console.error('Signature pad not initialized.');
    }
  };

  const handleClear = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
      setSignatureSaved(null);
    } else {
      console.error('Signature pad not initialized.');
    }
  };

  return (
    <div className="signature-container">
      <SignatureCanvas
        ref={signaturePadRef}
        canvasProps={{
          className: 'signature-pad',
        }}
      />
      <div className="btns" style={{ display: "flex" }}>
      <button className="save-button" onClick={handleSave}>
        Save Signature
      </button>
      <button className="clear-button .download-button--clear" onClick={handleClear}>
        Clear Signature
      </button>
      </div>
    </div>
  );
};

export default SignaturePad;
