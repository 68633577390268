import { createContext, useContext, useState } from 'react';

const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const [signatureSaved, setSignatureSaved] = useState(null);

  const contextValue = {
    signatureSaved,
    setSignatureSaved: (dataURL) => setSignatureSaved(dataURL),
  };

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error('useEvent must be used within an EventProvider');
  }

  return context;
};
