// index.js or App.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // or your main component
import { EventProvider } from '../src/components/EventContext';

ReactDOM.render(
  <React.StrictMode>
    <EventProvider>
      <App />
    </EventProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
