import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { Button, Container, Grid, CircularProgress } from "@mui/material";
import { dataURLtoBlob } from "./utils/fileUtils";
import { useEvent } from "./EventContext";
import SignaturePad from "./SignaturePad";
import "./Style.scss";

const DownloadOptions = () => {
  const { signatureSaved, setSignatureSaved } = useEvent();
  const signatureRef = useRef();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false); // State for signature pad loading

  const handleDownload = (format, fileName) => {
    if (signatureSaved) {
      const blob = dataURLtoBlob(signatureSaved);
      saveAs(blob, fileName);
    } else {
      console.error(
        "Signature not found. Please draw or import before downloading."
      );
    }
  };

  const handleDownloadPDF = () => {
    if (signatureSaved) {
      setSaving(true);
      const pdf = new jsPDF();
      const imgData = signatureSaved;

      // Add the image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);

      pdf.save("signature.pdf");
      setSaving(false);
    } else {
      console.error(
        "Signature not found. Please draw or import before downloading."
      );
    }
  };

  const handleSaveSignature = () => {
    try {
      const signatureData = signatureRef.current.toDataURL();
      localStorage.setItem("savedSignature", signatureData);
      setSignatureSaved(signatureData);
      console.log("Signature saved successfully!");
    } catch (error) {
      console.error("Error saving signature:", error);
    }
  };

  useEffect(() => {
    if (saving) {
      console.log("Downloading PDF...");
    }
  }, [saving]);

  return (
    <Container
      className="app-container"
      style={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        placeItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* Conditionally render a loading message until the signature pad is ready */}
          {!loading && <SignaturePad ref={signatureRef} />}
          {loading && <p>Loading Signature Pad...</p>}
        </Grid>
      </Grid>
      <div className="btnsContainer">
        <Button
          className="download-button download-button--png"
          disabled={!signatureSaved}
          onClick={() => handleDownload("image/png", "signature.png")}
        >
          {saving ? <CircularProgress size={24} /> : "Download PNG"}
        </Button>
        <Button
          className="download-button download-button--pdf"
          disabled={!signatureSaved}
          onClick={handleDownloadPDF}
        >
          {saving ? <CircularProgress size={24} /> : "Download PDF"}
        </Button>
      </div>
    </Container>
  );
};

export default DownloadOptions;
