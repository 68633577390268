import React from "react";
import './Style.scss'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <p>
          © {new Date().getFullYear()} <a href="http://lacspace.com" title="A web developement company">Lacspace</a>. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
