// fileUtils.js
const dataURLtoBlob = (dataURL) => {
    if (!dataURL || typeof dataURL !== 'string') {
      throw new Error('Invalid data URL');
    }
  
    const arr = dataURL.split(',');
    if (arr.length !== 2) {
      throw new Error('Invalid data URL format');
    }
  
    const mime = arr[0].match(/:(.*?);/);
    if (!mime) {
      throw new Error('Invalid mime type in data URL');
    }
  
    const mimeType = mime[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new Blob([u8arr], { type: mimeType });
  };
  
  export { dataURLtoBlob };
  