import React from 'react';
import DownloadOptions from './components/DownloadOptions';
import { EventProvider } from './components/EventContext';
import Footer from './components/Footer';
import AboutModal from './components/AboutModal';

function App() {
  return (
    <EventProvider>
      <div>
        <AboutModal />
        <DownloadOptions />
        <Footer />
      </div>
    </EventProvider>
  );
}

export default App;
