import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import "./Style.scss";

const AboutModal = () => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setOpenModal(true), 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleClose = () => setOpenModal(false);

  return (
    <Modal open={openModal} onClose={handleClose}>
      <Box className="modal-container">
        <div className="modal">
          <Typography variant="h6" component="h2" className="modal-title">
            Explore the Power of Signatures
          </Typography>
          <Typography variant="body1" className="modal-body">
            Elevate your digital experience with our signature, offering endless possibilities:
            <ul className="modal-list">
              <li>
                <span className="icon">📄</span> Electronically sign important documents
              </li>
              <li>
                <span className="icon">📧</span> Add a personalized touch to your emails
              </li>
              <li>
                <span className="icon">🚀</span> Create custom signatures
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" className="modal-body">
            Our signature is not just a mark; it's a statement. Feel free to use it for personal and commercial purposes.
          </Typography>
          <Button variant="contained" onClick={handleClose} className="modal-close">
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default AboutModal;
